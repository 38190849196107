import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { SectionTitle } from '../../components/section-title'
import { MainLayout } from '../../layouts/main'

import * as styles from './index.module.css'

export default function AboutPage() {
  const teamMembers = useMemo(
    () => [
      {
        image: { src: require('./staff-images/nick-gabrielli.jpg').default },
        name: 'Ignacio Gabrielli (Nick)',
        title: 'Co-Owner, Director of Operations',
        bio: (
          <>
            <p>
              Nick is the owner and founder of Old Biscayne Designs.He directed
              and expanded a corporate portfolio ranging from cattle farms to
              international banking with his father in Buenos Aires, Argentina.
              His acute financial acumen has kept OBD nimble and fleet-footed
              while navigating the ever-changing market demand. Steering their
              early enterprise (of buying and selling European antiques from
              Buenos Aires) into a multi-factory manufacturing endeavor allowed
              Nick to provide OBD with the expertise to develop and refine a
              process for creating furniture for each designer’s special vision.
              Under his management, OBD procures US hardwoods and steel and
              coordinates all the processes from iron work, foundry work,
              hand-carving, and hand-crafting to the delivery of one-of-a-kind
              furniture designs.
            </p>
          </>
        ),
      },
      {
        image: {
          src: require('./staff-images/gretchen-gabrielli.jpg').default,
        },
        name: 'Gretchen Gabrielli',
        title: 'Co-Owner, Director of Sales and Marketing',
        bio: (
          <>
            <p>
              Gretchen is the Co-owner and Co- Founder of Old Biscayne Designs,
              which she and her husband Ignacio (Nick) Gabrielli founded in
              1990. After receiving a Design and Marketing degree from Georgia
              Southwestern University, she designed women’s sportswear
              collections in Los Angeles and Miami. Twelve years of design and
              merchandising for the clothing industry provided the foundation to
              begin importing antiques from Buenos Aires, Argentina. Importing
              progressed into manufacturing a collection of antique-inspired
              furniture designs scaled for the American market. Her ability to
              read fashion trends, an eye for scale and proportion, and an
              intense sensitivity for personal style lends itself to her
              professional ability to translate personal style traits for the
              lifestyles of her design clientele. Do you look good in your
              furniture?
            </p>
          </>
        ),
      },
      {
        image: { src: require('./staff-images/chloe-matisse.jpg').default },
        name: 'Chloe Matisse Gabrielli',
        title: 'Affectionately called Mati',
        bio: (
          <>
            <p>
              Mati, our two-year-old, silver labrador, is quite dedicated and
              loyal to her position at OBD. Her responsive therapy, including
              licks, love, and tail wagging, is quite effective. We could all
              learn from her stress management techniques. Perform and reward
              communication has worked brilliantly as her training regimen.
              Krista recently taught her the Hokie Pokie with a few hundred dog
              biscuits, and Sebie relies heavily on Mati for biscuit disposal
              every morning.
            </p>
          </>
        ),
      },
      {
        image: { src: require('./staff-images/adrian-woods.jpg').default },
        name: 'Adrian Woods',
        title: 'Production and Finishing Manager',
        bio: (
          <>
            <p>
              Starting in 2000, Adrian worked his way through our factory,
              acquiringevery skill required to manufacture high-end furniture.
              His dedication and discerning eye for detail have made for a
              valuable employee that functions at a high level in the very heart
              of OBD. Among other things, Adrian is also very talented with the
              ladies. All of our female employees smile a little brighter when
              he’s around. We teasingly call him the Barry White of
              manufacturing.
            </p>
          </>
        ),
      },
      {
        image: { src: require('./staff-images/brenda-cavender.jpg').default },
        name: 'Brenda Cavender',
        title: 'Office Manager',
        bio: (
          <>
            <p>
              Since 1998, Brenda has been our office task master. Over and above
              her financial and accounting duties she is our resident Mother and
              Grandmother helping to keep the team on point. Today she handles
              all financial and freight inquires, although she is practically an
              expert in all OBD office operations. She also bakes the best
              caramel cake in the world.
            </p>
          </>
        ),
      },
    ],
    [],
  )

  return (
    <MainLayout>
      <div className={styles.about}>
        <SectionTitle>About us</SectionTitle>
        <Quote />
        <Intro />

        <SectionTitle>Meet the Old Biscayne Team</SectionTitle>
        {teamMembers.map((member, i) => (
          <Bio
            key={i}
            image={member.image}
            bio={member.bio}
            name={member.name}
            title={member.title}
          />
        ))}
      </div>
    </MainLayout>
  )
}

function Quote() {
  return (
    <div>
      <img alt="" src={require('./quote.svg')} />
    </div>
  )
}

function Intro() {
  return (
    <div className={styles.intro}>
      <p>
        Since 1990, Old Biscayne Designs has manufactured fine iron beds and
        wood furniture designs derived from European antiques. Nick and Gretchen
        Gabrielli welded their cultures; his of Argentine aristocratic elegance
        and hers of South simplicity and charm to form a company that handcrafts
        furniture to each customer’s specifications. Every design is signed with
        the Gabrielli signature and year of creation. Their absolute dedication
        to excellence guarantees that the owner of a Gabrielli design will have
        the very best.
      </p>

      <br />
    </div>
  )
}

function Bio(props) {
  const { bio, image, name, title, ...rest } = props

  return (
    <div className={styles.bio} {...rest}>
      <div className={styles.bioImage}>
        <img alt={name} src={image.src} />
      </div>

      <div className={styles.bioContent}>
        <h3 className={styles.bioName}>{name}</h3>
        <h4 className={styles.bioTitle}>{title}</h4>

        <div className={styles.bioCopy}>{bio}</div>
      </div>
    </div>
  )
}
Bio.propTypes = {
  bio: PropTypes.node.isRequired,
  image: PropTypes.shape({ src: PropTypes.string.isRequired }).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
